<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.49967 14C4.77567 14 5 14.2243 5 14.5003C5 14.7763 4.77567 15 4.49967 15C4.22368 15 4 14.7763 4 14.5003C4 14.2243 4.22368 14 4.49967 14Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.5 14C13.7758 14 14 14.2243 14 14.5003C14 14.7763 13.7758 15 13.5 15C13.2242 15 13 14.7763 13 14.5003C13 14.2243 13.2242 14 13.5 14Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 1L2.57405 1.26694L3.3028 9.77428C3.36183 10.4676 3.95285 11 4.66269 11H12.9204C13.5984 11 14.1736 10.5121 14.2712 9.85363L14.9893 4.99081C15.0779 4.39092 14.6034 3.85407 13.9851 3.85407H2.8268"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
